<template>
    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }">
        <v-col cols="12">
            <v-card class="full-width" color="q_new_b_1">
                <h4 class="white--text pl-5 py-3 mb-0">Event Registration Leaderboards</h4>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-btn x-large color="primary" to="/page/2022-reimagine-national-conference-contest-results">View All Final Contest Winners Here</v-btn>
        </v-col>
        <!-- Hiding all leaderboards as the contests are now over (8/12) -->
        <!-- <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="overallbaseshop" :agent-code="user.AgentCode" title="Baseshop Promoter" :rows="10">
            </q-events-leaderboard-data-table>
        </v-col>
        <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="overalldirect" :agent-code="user.AgentCode" title="Direct Agency Promoter" :rows="10">
            </q-events-leaderboard-data-table>
        </v-col>
        <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="keyleader" :agent-code="user.AgentCode" title="Key Leader Promoter" :rows="10">
            </q-events-leaderboard-data-table>
        </v-col>
        <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="agencyowner" :agent-code="user.AgentCode" title="Agency Owner Promoter" :rows="10">
            </q-events-leaderboard-data-table>
        </v-col>
        <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="agencydirector" :agent-code="user.AgentCode" title="Agency Director Promoter" :rows="10">
            </q-events-leaderboard-data-table>
        </v-col>
        <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="teamleader" :agent-code="user.AgentCode" title="Team Leader Promoter" :rows="10">
            </q-events-leaderboard-data-table>
        </v-col>
        <v-col lg="4" md="6" sm="12">
            <q-events-leaderboard-data-table type="leadersreception" :agent-code="user.AgentCode" title="Leaders Reception Qualification*" :rows="10">
            </q-events-leaderboard-data-table>
            <small>*A minimum of 15 registrations is required to qualify for the Leaders Reception.</small>
        </v-col> -->
    </v-row>
</template>
<script>
import PromotionLevels from '@/store/PromotionLevels.json'
// import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'
// import moment from 'moment'
import QEventsLeaderboardDataTable from '@/components/datatables/QEventsLeaderboardDataTable.vue'
export default {

    data() {
        return {
            agentContractLevel: null,
            personalFilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'personal'
            },
            baseshopFilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'baseshop'
            },
            totalagencyfilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'totalagency'
            },
            directagencyfilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'directagency'
            },
            agent: null,
            datePeriod: { label: 'Current Month', 'period': 'month' }
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        'default_placed_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
        'default_uw_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.UniqueWriters
        },
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        'time_period': function() {
            if (this.datePeriod.period == 'week') {
                return 'weekly'
            }
            if (this.datePeriod.period == 'month') {
                return 'monthly'
            }
            if (this.datePeriod.period == 'year') {
                return 'yearly'
            }
        }
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/new_business/');
                return
            }
            this.$router.push('/my_stats/scorecard/new_business/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + this.agent_code);
        }
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QEventsLeaderboardDataTable
    }
}

</script>

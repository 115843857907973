<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu has-demo="true" v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row class="mx-7" style="padding-top:100px;">
            <v-card class="flex" id="MyStats" cols="12">
                <v-col cols="12" class="indigo pa-1">
                    <p class="q-display-3 pl-5 pt-3 white--text">
                        My Stats
                    </p>
                </v-col>
                <q-sfg-stats v-on:loaded="maybeAutoStartDemo('DemoMyStats')" :agent="user"></q-sfg-stats>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
import QSfgStats from '@/components/stats/QSfgStats.vue';
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';

export default {
    data() {
        return {}
    },
    mounted: function() {
},

    computed: {},
    methods: {
        'startDemo': function() {
            this.$tours['DemoMyStats'].start()
        },
    },
    watch: {

    },
    components: {
        QMyStatsSubmenu,
        QSfgStats
    }
}

</script>

<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu has-demo="true" v-on:demo="startDemo()"></q-my-stats-submenu>
        <!-- Charts-->
        <div class="mx-5 q-sticky-buffer">
            <v-card class="mx-auto ma-1 " color="" elevation="3" height="100%">
                <QDataTableHeader color="deep-purple">
                    <template #left>
                        <p class="q-display-3 white--text">Leaderboards</p>
                    </template>
                    <template #right>
                        <q-business-time-period-select :dark="true" v-model="datePeriod" label="Time Period" :allow-current="true" stateKey="stat"></q-business-time-period-select>
                    </template>
                </QDataTableHeader>

                <div class="d-flex flex-wrap q-flex-grid ma-n4 ma-md-0">
                    <div v-if="hasRole(['AgencyOwner', 'SalesRep'])">
                        <q-leaderboard-data-table-wrapper :rows="20" :show-all="true" title="Agency Baseshop" :agent-code="user.AgentCode" type="my_baseshop" :time-frame="time_period" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate" v-on:loaded="maybeAutoStartDemo('DemoMyStats')" key="my_baseshop"></q-leaderboard-data-table-wrapper>
                    </div>
                    <div v-if="hasRole('AgencyOwner') && user.LeadershipLevel !='Agency Owner'">
                        <q-leaderboard-data-table-wrapper :rows="20" :show-all="true" title="Total Agency" :agent-code="user.AgentCode" type="my_agency" :time-frame="time_period" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate" key="my_agency"></q-leaderboard-data-table-wrapper>
                    </div>
                    <div v-if="hasRole('AgencyOwner') && user.LeadershipLevel !='Agency Owner'">
                        <q-leaderboard-data-table-wrapper :rows=" 20" :show-all="true" title="Agency Owners" :agent-code="user.AgentCode" type="my_agencyowners" :time-frame="time_period" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate" key="my_agencyowners">
                        </q-leaderboard-data-table-wrapper>
                    </div>
                    <div v-if="hasRole(['Exec', 'SuperAdmin', 'Staff'])">
                        <q-leaderboard-data-table-wrapper :rows="20" :show-all="true" title="Total Agency" :agent-code="user.AgentCode" type="my_agency" :time-frame="time_period" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate" key="my_agency"></q-leaderboard-data-table-wrapper>
                    </div>
                </div>                    
            </v-card>
        </div>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QLeaderboardDataTableWrapper from '@/components/datatables/QLeaderboardDataTableWrapper.vue'
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QDataTableHeader from '../../components/datatables/base/QDataTableHeader.vue'

export default {
    data() {
        return {
            datePeriod: { label: 'Current Month', 'period': 'month' },
        }
    },
    computed: {
        time_period() {
            const timePeriodMap = {
                week: 'weekly',
                month: 'monthly',
                year: 'yearly',
            };

            return timePeriodMap[this.datePeriod.period];
        },
    },
    methods: {
        'startDemo': function() {
            this.$tours['DemoLeaderboards'].start()
        },
    },
    watch: {

    },
    components: {
        QLeaderboardDataTableWrapper,
        QMyStatsSubmenu,
        QBusinessTimePeriodSelect,
        QDataTableHeader
    }
}
</script>

<style lang="scss" scoped>
.q-flex-grid {
    display: flex;
    padding: 0.5rem;

    & > * {
        text-align: center;
        flex-grow: 1;
        padding: 0.5rem;
    }
}
</style>
<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false" has-demo="true" v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row wrap class="mx-7" :style="showSubmenu !== false ? 'padding-top:100px;' : ''">
            <v-card class="full-width">
                <q-score-cards-all></q-score-cards-all>
                <q-events-all-leaderboard-data-table></q-events-all-leaderboard-data-table>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue'
import QScoreCardsAll from '@/components/stats/scorecards/QScoreCards_EventsAll.vue'
import QEventsAllLeaderboardDataTable from '@/components/datatables/QEventsAllLeaderboardDataTable.vue'
import PromotionLevels from '@/store/PromotionLevels.json'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ["showSubmenu", "allowAgentLookup"],
    props: {

        showSubmenu: {
            type: Boolean,
            default: true
        },
        allowAgentLookup: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            agentContractLevel: null,
            personalFilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'personal'
            },
            baseshopFilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'baseshop'
            },
            totalagencyfilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'totalagency'
            },
            directagencyfilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'directagency'
            },
            agent: null,
            datePeriod: { label: 'Current Month', 'period': 'month' }
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        'default_placed_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
        'default_uw_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.UniqueWriters
        },
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        'time_period': function() {
            if (this.datePeriod.period == 'week') {
                return 'weekly'
            }
            if (this.datePeriod.period == 'month') {
                return 'monthly'
            }
            if (this.datePeriod.period == 'year') {
                return 'yearly'
            }
        }
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/new_business/');
                return
            }
            this.$router.push('/my_stats/scorecard/new_business/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + this.agent_code);
        }
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QEventsAllLeaderboardDataTable,
        QMyStatsSubmenu,
        QScoreCardsAll

    }
}

</script>

<!-- No longer used, was replaced by the agent directory for AOs and the new agent detail pages...  -->
<template>
    <div>
        <v-progress-linear indeterminate v-if="loading || agent_loading"></v-progress-linear>
        <div>
            <q-drilldown-events-aggregate-data-table :loading="loading || agent_loading" :title="the_title" :the-data="the_data" :headers="headers" v-on:filters="updateFilters" :filters="filters" @reload="loadData" :agent-code="agent_code" :rows="25" :agent-name="agent_name" :personal-invites="totalInvites">
            </q-drilldown-events-aggregate-data-table>
        </div>
    </div>
</template>
<script>
/*
Force re-rendering of this component on this view...
set a key and anytime the agentcode changes we will increment the componentKey and it will re-render the component.
 */

import QDrilldownEventsAggregateDataTable from '@/components/datatables/Drilldowns/QDrilldownEventsAggregateDataTable.vue';
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QScoreCardPieChart from '@/components/stats/scorecards/QScoreCardPieChart.vue'
import { debounce } from 'vue-debounce'
export default {
    name: "QRegistrationsDrilldown",
    props: [],
    data() {
        return {
            the_data: null,
            agent: null,
            loading: false,
            agent_loading: false,
            datePeriod: {},
            backup_filters: {},
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'TotalAgency'
            },
            totalInvites: 0,
            activatedCall: false,
        }
    },
    mounted: function() {
        this.filters = {
            endDate: this.$route.query.endDate,
            startDate: this.$route.query.startDate,
            timePeriod: this.$route.query.timePeriod,
            statType: this.$route.query.statType
        }
        this.datePeriod = {
            endDate: this.$route.query.endDate,
            startDate: this.$route.query.startDate,
            period: this.$route.query.timePeriod
        }

        var g = this
        this.backup_filters = this.filters
        this.loading = true
        this.activatedCall = false
        this.loadAgent(this.agent_code).then(function() {
            g.loadData();
        })
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agent_code != 'undefined' && this.$route.params.agent_code != null) {
                return this.$route.params.agent_code;
            }
            return this.user.Agent.AgentCode;
        },
        headers: function() {
            let personalHeaders = [{
                    text: 'First Name',
                    value: 'FirstName'
                },
                {
                    text: 'Last Name',
                    value: 'LastName'
                },
                {
                    text: 'Registration Date',
                    value: 'RegistrationDate'
                },
                {
                    text: 'Tickets',
                    value: 'Tickets'
                },
                {
                    text: 'Email',
                    value: 'Email'
                },
                {
                    text: 'Phone',
                    value: 'Phone'
                },
            ]

            let headers = [{
                    text: 'First Name',
                    value: 'FirstName'
                },
                {
                    text: 'Last Name',
                    value: 'LastName'
                },
                {
                    text: 'Registering Agent',
                    value: 'Ticket_Data'
                },
                {
                    text: 'Registration Date',
                    value: 'RegistrationDate'
                },
                {
                    text: 'Tickets',
                    value: 'Tickets'
                },
                {
                    text: 'Email',
                    value: 'Email'
                },
                {
                    text: 'Phone',
                    value: 'Phone'
                }
            ]

            return this.filters.statType == 'personal' ? personalHeaders : headers
        },
        the_title: function() {
            if (typeof this.agent == 'undefined' || this.agent == null) {
                return 'Registration Details'
            }
            return this.agent.AgentName != null ? this.agent.AgentName + ' Registration Details' : 'Registration Details'
        },
        agent_name: function() {
            if (typeof this.agent == 'undefined' || this.agent == null) {
                return null
            }
            return this.agent.AgentName != null ? this.agent.AgentName : null
        },
        //these fields are being used???
        //exporting exactly what is in the API returned data.
        export_fields: function() {
            return ['AgentID', 'AgentCode', 'AgentName', 'OptID', 'Apv', 'Apps', 'LeadershipLevel', 'ContractLevel', 'AgentEmail', 'StreetAddress', 'City', 'County', 'State', 'Zip', 'AgentPhone', 'Status', 'Division'];
        }
    },
    methods: {
        updateFilters: function(f) {
            this.filters = f
            this.backup_filters = this.filters
        },
        loadData: function() {
            var g = this
            this.loading = true
            //get the data for the agent for this scorecard.
            if (this.agent == null) {
                return
            }
            if (typeof this.filters == 'undefined' || typeof this.filters.timePeriod == undefined) {
                this.filters = this.backup_filters
                return
            }
            var params = {
                ...this.filters
            }
            //if this is just an agency owner, then we override the filter and request the base shop.
            if (params.statType == "TotalAgency" && this.agent.HasTotalAgency === false) {
                params.statType = "Baseshop"
            }
            //if this is a second level drill down, then we have to force baseshop to make the numbers match the previous drill down.
            if (this.$route.params.forceBaseshop === true) {
                params.statType = "Baseshop"
            }

            g.the_data = []
            var q_name = "getDrillDown_event_registrations"
            QuilityAPI.cancelQRequest(q_name);
            QuilityAPI.getRequest("/api/private/scorecards/agent_events/" + this.agent_code + "/drilldown", params, q_name).then(function(json) {
                    g.the_data = json.data.registration_data
                    g.totalInvites = json.data.personal_invitations
                    g.loading = false;
                },
                function(error) {
                    if (!g.activatedCall) {
                        g.loading = false
                    }
                    console.log(error)
                    return error
                })
        },
        loadAgent: function(agentCode) {
            var g = this
            // g.agent_loading = true;
            return QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                g.$set(g, 'agent', json);
                // g.agent_loading = false
            }).catch(function(err) {
                console.log(err)
                g.agent_loading = false
            })
        },
    },
    components: {
        QBusinessTimePeriodSelect,
        QScoreCardPieChart,
        QDrilldownEventsAggregateDataTable,
    },
    activated() {
        // this.refreshData()
        // console.log('recalling data')
        this.filters = {
            endDate: this.$route.query.endDate,
            startDate: this.$route.query.startDate,
            timePeriod: this.$route.query.timePeriod,
            statType: this.$route.query.statType
        }
        this.datePeriod = {
            endDate: this.$route.query.endDate,
            startDate: this.$route.query.startDate,
            period: this.$route.query.timePeriod
        }

        var g = this
        this.backup_filters = this.filters
        this.loading = true
        this.activatedCall = true
        this.loadAgent(this.agent_code).then(function() {
            g.loadData();
        }).catch(function(err) {
            console.log(err)
            g.loading = false
        })
    }
}
</script>
<style scoped>
</style>
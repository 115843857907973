<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false"></q-my-stats-submenu>
        <v-row wrap class="mx-7" :style="showSubmenu !== false ? 'padding-top:100px;' : ''">
            <v-col class="pa-0" cols="12" v-if="showSubmenu !== false">
                <h3 v-if="agent">
                    {{agent.AgentName}} - Summit Stats
                </h3>
            </v-col>
            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="9" lg="10" v-if="agent">
                    <v-row wrap>

                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-summit-started :agent-code="agent_code" :filters="filters" key="summit-started">
                            </q-score-card-summit-started>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-summit-started-periodic :agent-code="agent_code" :filters="filters" key="summit-started-periodic">
                            </q-score-card-summit-started-periodic>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-summit-phase-completed phase="Activate"
                                                                 :agent-code="agent_code"
                                                                 :filters="filters"
                                                                 key="summit-activate-completed">
                            </q-score-card-summit-phase-completed>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-summit-phase-completed phase="Accelerate"
                                                                 :agent-code="agent_code"
                                                                 :filters="filters"
                                                                 key="summit-accelerate-completed">
                            </q-score-card-summit-phase-completed>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-summit-agent-progress-report :agent-code="agent_code" :filters="filters" card-style="summit-stats"></q-summit-agent-progress-report>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="2" v-if="agent">
                    <q-score-card-filters v-model="filters" :agent="agent" v-on:agent="changeAgent" :allow-agent-lookup="allowAgentLookup"></q-score-card-filters>
                </v-col>
            </v-row>


            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="9" lg="10" v-if="agent" >
                    <q-summit-progress-table title="Summit Progress" :filters="filters" :agent-code="agent_code"></q-summit-progress-table>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';

import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { isDisputeTimePeriod } from '@/components/utils/BusinessTimeDateDiff'
import QScoreCardSummitStarted from "@/components/stats/scorecards/QScoreCardSummitStarted";
import QScoreCardSummitStartedPeriodic from "@/components/stats/scorecards/QScoreCardSummitStartedPeriodic";
import QSummitProgressTable from "@/components/datatables/QSummitProgressTable";
import QScoreCardFilters from "@/components/stats/scorecards/QScoreCardFilters";
import QSummitAgentProgressReport from "@/components/datatables/QSummitAgentProgressReport";
import QScoreCardSummitPhaseCompleted from "@/components/stats/scorecards/QScoreCardSummitPhaseCompleted";

export default {
    props: {
        showSubmenu: {
            type: Boolean,
            default: true
        },
        allowAgentLookup: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            agentContractLevel: null,
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
            agent: null
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        canDispute: function() {
            return isDisputeTimePeriod()
        },
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/hq/');
                return
            }
            this.$router.push('/my_stats/scorecard/hq/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + this.agent_code);
        },
        launchDisputeForm() {
            // open link to dispute form
            // housed in jot forms
            window.open('https://form.jotform.com/211966371426055', '_blank');
        },
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QScoreCardSummitPhaseCompleted,
        QSummitAgentProgressReport,
        QSummitProgressTable,
        QScoreCardSummitStartedPeriodic,
        QScoreCardSummitStarted,
        QMyStatsSubmenu,
        QScoreCardFilters,

    }
}
</script>

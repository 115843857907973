<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <div class="deep-purple lighten-5 pt-4 pb-1 pl-4">
            <div v-if="subtitle" class="overline">{{subtitle}}</div>
            <h4>{{the_title}}</h4>
        </div>
        <v-data-table light v-if="!loading && leaderboard_data.data.length > 0" dense :mobile-breakpoint="2" :items-per-page.sync="rows" :headers="the_headers" :items="leaderboard_data.data" item-key="AgentCode" class="elevation-1 leaderboard-datatable" :hide-default-footer="false" :footer-props="{itemsPerPageOptions:[10,25,50,100, -1]}">
            <template #item="{ item, index, headers }">
                <tr>
                    <td v-for="n in headers">
                        {{ n.value === 'index' ? item.Rank : item[n.value] }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import QLeaderboardDataTable from './QLeaderboardDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QEventsLeaderboardDataTable",
    props: {

        'agentCode': {
            type: String,
            default: null
        },
        'type': {
            type: String,
            default: null
        },
        'timeFrame': {
            type: String,
            default: null
        },
        'title': {
            type: String,
            default: null
        },
        'subtitle': {
            type: String,
            default: null
        },
        'showAll': {
            type: Boolean,
            default: false
        },
        'rows': {
            type: Number,
            default: 25
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        }
    },
    data: function() { // data internal to component (not available outside)
        return {
            leaderboard_data: {
                data: {},
            },
            agentPosition: {
                data: {},
            },
            loading: false,
            displayStartDate: this.startDate,
            displayEndDate: this.endDate
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        the_title: function() {
            // return this.title + ' ' + this.titleCase(this.timeFrame) + ' Leaderboard'
            return `${this.title} ${this.timeFrame ? this.titleCase(this.timeFrame) : ''}`
        },
        the_headers: function() {
            return [{
                    value: 'index',
                    text: '#',
                },
                {
                    text: 'Name',
                    value: 'AgentName'
                },
                {
                    text: 'Total Registrations',
                    value: 'Total'
                }
            ]
        }
    },
    methods: {
        loadData: function() {
            var g = this
            this.loading = true;
            var filters = {
                startDate: this.startDate,
                endDate: this.endDate
            }
            QuilityAPI.loadEventLeaderboard(this.agentCode, this.type, filters)
                .then(function(json) {
                    g.$set(g.leaderboard_data, 'data', json.data)
                    g.loading = false
                })
                .catch(function(error) {
                    console.log('error', error)
                    g.loading = false
                })
        }
    },
    components: {
        QLeaderboardDataTable
    }
}

</script>
<style>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

</style>

<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu has-demo="true" v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row class="mx-7" style="padding-top:100px;">
            <v-card class="flex" id="MyStats" cols="12">
                <QTabs v-if="hasRole(['AgencyOwner'])" class="q-text-left lighest_grey" background-color="q_symm_blue" dark>
                    <v-tab @click="logTabActivty(user.Agent.LeadershipLevel != 'Agency Owner' ? 'TotalAgency' : 'BaseShop')">
                        <span id="v-owner-prod-1" v-if="user.Agent.LeadershipLevel != 'Agency Owner'">Total Agency</span>
                        <span v-else>Base Shop</span>
                    </v-tab>
                    <v-tab @click="logTabActivty('BaseShop')" v-if="user.Agent.LeadershipLevel != 'Agency Owner'"><span id="v-owner-base-shop">Base Shop</span></v-tab>
                    <v-tab @click="logTabActivty('MyProduction')" id="v-owner-personal">My Production</v-tab>
                    <v-tab-item class="lighest_grey">
                        <q-agent-stats ref="stats" :agent="user.Agent" v-on:loaded="maybeAutoStartDemo('DemoMyStats')"></q-agent-stats>
                    </v-tab-item>
                    <v-tab-item class="lighest_grey" v-if="user.Agent.LeadershipLevel != 'Agency Owner'">
                        <q-baseshop-stats :agent="user"></q-baseshop-stats>
                    </v-tab-item>
                    <v-tab-item class="lighest_grey">
                        <q-personal-production-stats :agent="user.Agent"></q-personal-production-stats>
                    </v-tab-item>
                </QTabs>
                <div v-else-if="user.Agent.LeadershipLevel == 'Key Leader'">
                    <QTabs class="q-text-left lighest_grey" background-color="q_symm_blue" dark>
                        <v-tab @click="logTabActivty('Key Leader')"><span id="v-owner-base-shop">Key Leader</span></v-tab>
                        <v-tab @click="logTabActivty('MyProduction')" id="v-owner-personal">My Production</v-tab>
                        <v-tab-item class="lighest_grey">
                            <q-agent-stats ref="stats" :agent="user.Agent" v-on:loaded="maybeAutoStartDemo('DemoMyStats')"></q-agent-stats>
                        </v-tab-item>
                        <v-tab-item class="lighest_grey">
                            <q-personal-production-stats :agent="user.Agent"></q-personal-production-stats>
                        </v-tab-item>
                    </QTabs>
                </div>
                <div v-else>
                    <q-agent-stats ref="stats" :agent="user.Agent" v-on:loaded="maybeAutoStartDemo('DemoMyStats')"></q-agent-stats>
                </div>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
import QAgentStats from '@/components/stats/QAgentStats.vue';
import QPersonalProductionStats from '@/components/stats/QPersonalProductionStats.vue';
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QBaseshopStats from '@/components/stats/QBaseshopStats.vue';
import QTabs from '../../components/navigation/QTabs.vue'

export default {
    methods: {
        'startDemo': function() {
            this.$tours['DemoMyStats'].start()
        },
    },

    components: {
        QPersonalProductionStats,
        QMyStatsSubmenu,
        QAgentStats,
        QBaseshopStats,
        QTabs
    }
}

</script>
